header {
	.navbar {
		font-size: 0.8125rem;
		box-shadow: 0 0 5px black;
	}
}

#portal-menu .brand-icon {
	max-height: 1.5em;
}
