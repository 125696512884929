.pagination {
	.page-item.active {
		.page-link {
			background-color: theme-color('sub');
			color: white;
			border-radius: 3px;
		}
	}

	.page-item {
		margin: 0 3px;
		.page-link {
			border-radius: 3px;
			background-color: white;
			color: theme-color('grey');
			font-size: 12px;
		}
	}
}
